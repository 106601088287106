import { createContext, useContext, useEffect, useMemo } from 'react'

import { ApolloError } from '@apollo/client/errors'
import {
  useFindInvoiceLazyQuery,
  useFindPartnerByPaymentsPortalUrlLazyQuery,
  CustomerPartnerFragment,
  CustomerFindInvoiceFragment
} from 'graph/generated/payments/graphql-types'
import { useRouter } from 'next/router'
import { createBrandColor } from 'utils/colors'
import { ColorProps } from 'utils/types'
import { origin, paymentsDomainLocalFix } from 'utils/window'

/**
 * Provider that supplies company information when a company url is provided.
 * Supplies invoice information when id is url param
 */

interface ICompanyAndInvoiceContext {
  company: CustomerPartnerFragment
  brandColor: ColorProps
  invoice: CustomerFindInvoiceFragment | null
  error: ApolloError
  loading: boolean
}

export const CompanyAndInvoiceContext =
  createContext<ICompanyAndInvoiceContext>({
    company: null,
    brandColor: null,
    invoice: null,
    error: null,
    loading: false
  })

const CompanyAndInvoiceProvider = ({ children }) => {
  const {
    query: { invoice }
  } = useRouter()

  const [fetchCompany, companyQuery] =
    useFindPartnerByPaymentsPortalUrlLazyQuery()
  const [fetchInvoice, invoiceQuery] = useFindInvoiceLazyQuery()

  const value = useMemo(() => {
    const company = companyQuery.data?.FindPartnerByPaymentsPortalUrl

    return {
      company,
      brandColor: createBrandColor(company?.paymentsPortalCustomization?.color),
      invoice: invoiceQuery.data?.FindInvoice,
      loading: companyQuery.loading,
      error: companyQuery.error || invoiceQuery.error
    }
  }, [companyQuery.data, invoiceQuery.data, companyQuery.loading])

  useEffect(() => {
    fetchCompany({ variables: { url: paymentsDomainLocalFix } })
    if (invoice) {
      fetchInvoice({ variables: { id: invoice as string } })
    }
  }, [invoice, origin])

  return (
    <CompanyAndInvoiceContext.Provider value={value}>
      {children}
    </CompanyAndInvoiceContext.Provider>
  )
}

export const useCompanyAndInvoiceContext = () =>
  useContext(CompanyAndInvoiceContext)

export default CompanyAndInvoiceProvider
