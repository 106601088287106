import { useEffect } from 'react'

import { ApolloProvider } from '@apollo/client'
import { datadogRum } from '@datadog/browser-rum'
import { ThemeProvider } from '@emotion/react'
import PartnerBoundary from 'components/boundaries/PartnerBoundary'
import ErrorBoundary from 'lib/boundaries/ErrorBoundary'
import MaintenanceMode from 'lib/layouts/MaintenanceMode'
import Root from 'lib/layouts/RootLayout'
import 'react-responsive-modal/styles.css'
import 'utils/css/reset.css'
import SegmentScript from 'lib/utils/SegmentScript'
import Seo from 'lib/utils/Seo'
import Zendesk from 'lib/utils/Zendesk'
import type { AppProps } from 'next/app'
import dynamic from 'next/dynamic'
import Router, { useRouter } from 'next/router'
import Script from 'next/script'
import setPageName from 'utils/analytics'
import theme from 'utils/theme'

import packageJson from '../package.json'
import CompanyAndInvoiceProvider from '@/hooks/providers/CompanyAndInvoiceProvider'
import { paymentsClient } from '@/utils/apollo'

const Toast = dynamic(() => import('lib/ui/Toast'), {
  ssr: false
})

declare global {
  interface Window {
    analytics: any
    gtag: any
  }
}

// Track client-side page views with Segment
Router.events.on('routeChangeComplete', () => {
  window?.analytics?.page(
    setPageName({ route: Router.route, app: 'Payments Portal' })
  )
})

{
  process.env.NEXT_PUBLIC_DATADOG_ID_PAYMENTS &&
    process.env.NEXT_PUBLIC_DATADOG_ID_PAYMENTS !== '' &&
    datadogRum.init({
      applicationId: process.env.NEXT_PUBLIC_DATADOG_ID_PAYMENTS,
      clientToken: process.env.NEXT_PUBLIC_DATADOG_TOKEN_PAYMENTS,
      site: 'datadoghq.com',
      service: 'payments-portal',
      version: packageJson.version,
      traceSampleRate: 100,
      sessionReplaySampleRate: 100,
      sessionSampleRate: 100,
      trackUserInteractions: true,
      defaultPrivacyLevel: 'mask-user-input',
      silentMultipleInit: true,
      allowedTracingUrls: [process.env.NEXT_PUBLIC_GRAPH_URI]
    })

  datadogRum.startSessionReplayRecording()
}

const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GA_TRACKING_ID

function PaymentsPortal({ Component, pageProps }: AppProps) {
  const router = useRouter()

  useEffect(() => {
    if (GA_TRACKING_ID) {
      const handleRouteChange = url => {
        window.gtag('config', GA_TRACKING_ID, {
          page_path: url
        })
      }

      router.events.on('routeChangeComplete', handleRouteChange)

      return () => {
        router.events.off('routeChangeComplete', handleRouteChange)
      }
    }
  }, [router.events])

  const segmentKey = process.env.NEXT_PUBLIC_SEGMENT_KEY

  const enableMaintenance = process.env.NEXT_PUBLIC_MAINTENANCE_MODE === 'true'

  return (
    <ApolloProvider client={paymentsClient}>
      <ThemeProvider theme={theme}>
        {enableMaintenance ? (
          <Root>
            <MaintenanceMode />
          </Root>
        ) : (
          <CompanyAndInvoiceProvider>
            <Seo />
            {GA_TRACKING_ID && (
              <>
                <Script
                  src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`}
                />
                <Script id='google-analytics'>
                  {`
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());

                    gtag('config', '${GA_TRACKING_ID}');
                  `}
                </Script>
              </>
            )}
            <SegmentScript app='Payments Portal' segmentKey={segmentKey} />
            <Toast />
            <Root>
              <ErrorBoundary>
                <PartnerBoundary>
                  <Component {...pageProps} />
                </PartnerBoundary>
              </ErrorBoundary>
            </Root>
          </CompanyAndInvoiceProvider>
        )}
        <Zendesk />
      </ThemeProvider>
    </ApolloProvider>
  )
}

export default PaymentsPortal
