import {
  OAuth2RefreshDocument,
  OAuth2RefreshMutationResult
} from 'graph/generated/payments/graphql-types'
import { GraphQLError } from 'graphql'
import Cookies from 'js-cookie'
import { AppCookie, getCookieExpires } from 'utils/cookies'

import { paymentsSSRClient } from '.'

export const oauth2RefreshPayments = async () => {
  try {
    const refreshToken = Cookies.get(AppCookie.RefreshCustomer)

    if (!refreshToken) {
      throw new GraphQLError('Missing refresh token')
    }

    const { data }: Pick<OAuth2RefreshMutationResult, 'data'> =
      await paymentsSSRClient.mutate({
        mutation: OAuth2RefreshDocument,
        variables: {
          refreshToken
        },
        context: {
          headers: {
            'X-Origin': process.env.NEXT_PUBLIC_PORTAL_DOMAIN ?? ''
          }
        }
      })

    if (!data?.OAuth2Refresh?.id_token || !data?.OAuth2Refresh?.refresh_token) {
      throw new GraphQLError('Invalid token response')
    }

    Cookies.set(AppCookie.TokenCustomer, data?.OAuth2Refresh?.id_token, {
      expires: getCookieExpires(AppCookie.TokenCustomer),
      httpOnly: false
    })

    Cookies.set(AppCookie.RefreshCustomer, data?.OAuth2Refresh?.refresh_token, {
      expires: getCookieExpires(AppCookie.RefreshCustomer),
      httpOnly: false
    })

    const customerId = Cookies.get(AppCookie.CustomerId)

    if (!customerId) {
      throw new GraphQLError('Missing customerId')
    }

    Cookies.set(AppCookie.CustomerId, customerId, {
      expires: getCookieExpires(AppCookie.CustomerId),
      httpOnly: false
    })
  } catch (err) {
    throw new GraphQLError(err)
  }
}
