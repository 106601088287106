import { makeVar, ReactiveVar } from '@apollo/client'

const getCleanValueForStorage = value => {
  if (typeof value === 'string') {
    return value
  }
  return JSON.stringify(value)
}

const makeVarPersisted = <T>(
  initialValue: T,
  storageName: string
): ReactiveVar<T> => {
  let value = initialValue

  if (typeof window !== 'undefined') {
    const previousValue = localStorage.getItem(storageName)
    if (previousValue !== null) {
      try {
        const parsed = JSON.parse(previousValue)
        value = parsed
      } catch {
        value = previousValue as unknown as T
      }
    }
    const rv = makeVar<T>(value)
    const onNextChange = (newValue: T | undefined) => {
      try {
        if (newValue === undefined) {
          localStorage.removeItem(storageName)
        } else {
          localStorage.setItem(storageName, getCleanValueForStorage(newValue))
        }
      } catch (e) {
        return e
      }
      rv.onNextChange(onNextChange)
    }
    rv.onNextChange(onNextChange)
    return rv
  } else {
    const rv = makeVar<T>(value)
    return rv
  }
}

export default makeVarPersisted
