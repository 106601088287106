import { ApolloClient, ApolloLink, from, InMemoryCache } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import Cookies from 'js-cookie'
import { handleErrors, httpLink, retryLink, ssrErrorLink } from 'utils/apollo'
import { AppCookie } from 'utils/cookies'

import { paymentsCache } from './cache'
import { oauth2RefreshPayments } from './refresh'

const paymentsErrorLink = onError(errors =>
  handleErrors(errors, oauth2RefreshPayments)
)

/**
 * Apollo link for customer authentication.
 * Authentication is done via token.
 * X-Customer-Id is used to identify the selected team.
 * X-Origin is used to bypass the Origin in local development.
 */
const paymentsAuthLink = new ApolloLink((operation, forward) => {
  const headers: Record<string, string> = {}

  const token = Cookies.get(AppCookie.TokenCustomer)
  if (token) {
    headers.authorization = `Bearer ${token}`
  }

  const customerId = Cookies.get(AppCookie.CustomerId)
  if (customerId) {
    headers['X-Customer-Id'] = customerId
  }

  if (process.env.NEXT_PUBLIC_PORTAL_DOMAIN) {
    headers['X-Origin'] = process.env.NEXT_PUBLIC_PORTAL_DOMAIN
  }

  operation.setContext({ headers })

  return forward(operation)
})

/* SSR shares cache in server so we never wanna use it */
export const paymentsSSRClient = new ApolloClient({
  link: from([ssrErrorLink, httpLink]),
  cache: new InMemoryCache(),
  ssrMode: true,
  defaultOptions: {
    watchQuery: {
      errorPolicy: 'all',
      fetchPolicy: 'no-cache'
    },
    query: {
      errorPolicy: 'all',
      fetchPolicy: 'no-cache'
    },
    mutate: {
      fetchPolicy: 'no-cache'
    }
  }
})

export const paymentsClient = new ApolloClient({
  // order matters here - put errorLink first so the refresh token flow works
  link: from([paymentsErrorLink, paymentsAuthLink, retryLink, httpLink]),
  cache: paymentsCache,
  ssrMode: false,
  defaultOptions: {
    watchQuery: {
      errorPolicy: 'all'
    },
    query: {
      errorPolicy: 'all'
    }
  }
})
